<template>
  <div>
     <van-popup v-model="phoneShow" round position="bottom" :style="{ height: '400px' }" :close-on-click-overlay="false" @click-overlay="cklOverlay">
      <img src="@/assets/images/index/closeimg.png" alt="" class="closeimg" @click="clkIcon">
      <img src="@/assets/images/guide/bgline.png" alt="" class="line">
      <div class="popup-header">
        <img src="@/assets/images/guide/bindphone.png" alt="" class="popup-img">
        <div class="right">
          <p class="p1">为此设备绑定手机号</p>
          <p class="p2">设备绑定手机后，需实名认证才可正常使用</p>
        </div>
      </div>
      <div class="from">设备号:{{iccid}}</div>
      <input type="tel" v-model="phone" class="vipnut" placeholder="请输入手机号" maxlength="11">
      <van-cell-group class="newvipnut">
        <van-field v-model="sms" center clearable placeholder="请输入短信验证码" maxlength="6" type="tel">
        <template #button>
          <van-button size="small" type="primary" @click="sendcode" :disabled="disabled" >{{buttontext}}</van-button>
        </template>
        </van-field>
      </van-cell-group>
      <div class="button" @click="bindtel" v-if="isbuttonshow">
        确认绑定
      </div>
       <div class="newbutton" v-else>
        绑定中
      </div>
    </van-popup>
  </div> 
</template>  
<script>
export default {
  data() {
    return{
      buttontext:"获取验证码",  // 获取验证码按钮的文字
      isbuttonshow:true,  // 是否确认绑定
      num:60,  // 倒计时开始的时间
      phone:"",    // 用户的手机号
      sms:"",  //用户的验证码
      disabled:false,  // 获取验证码的按钮能否被点击
    }
  },
  props:{
    phoneShow:{
      type:Boolean,
      default:false
    },  // 手机号认证弹窗
    iccid:{
      type:String,
      default:""
    },  // 用户的设备号

  },
  methods:{
    clkIcon(){
      this.$emit("clkIcon")
    },
    // 点击遮罩层的时候触发
    cklOverlay(){
      this.$emit("overlay")
    },
    // 索朗手机号绑定
    sorang(data){
      this.setRealState({data}).then(res =>{
        if(res.data.code == 1){
           this.$toast("绑定成功")
           this.$emit("overlay")
          }
          if(res.data.code == 0){
            this.$toast(res.data.msg)
            this.$emit("overlay")
            setTimeout(() =>{
               this.isbuttonshow = true
            },1000)
          }
      })
    },
    // 绑定手机号
    bindtel(){
      if(this.phone =="" || this.sms ==""){
        this.$toast("请填写验证码或手机号")
        this.$emit("overlay")
        return false
      }else{
        this.isbuttonshow = false
        let data = {
          iccid:this.iccid,
          phone:this.phone,
          code:this.sms,
          platform:1
        }
       this.sorang(data)
      }
    },
     // 发送验证码
    sendcode(){
      if(this.phone == ""){
        this.$toast("请输入手机号")
        return false
      }else{
        this.disabled = true
        const settitle = setInterval(() => {
          if(this.num > 0){
            this.num --
            this.buttontext = this.num+"秒" 
          }else{
            this.num = 60
            this.buttontext = "获取验证码" 
            this.disabled = false
            clearInterval(settitle)
          }
        }, 1000);
        let data = {
          iccid:this.iccid,
          phone:this.phone
        }
        this.sendCode({data}).then(res =>{
          res.data.code == 1?this.$toast.success('发送成功'):this.$toast(`${res.data.msg}`)
        })  
      }
    },
  }
}
</script>
<style lang="less" scoped>
  .closeimg{
    width: 22px;
    height: 22px;
    display: block;
    position: absolute;
    top:20px;
    right:20px;
  }
  .line{
    height: 6px;
    width: 100%;
    display: block;
  }
  /deep/.van-popup--bottom{
    border-radius: 6px 6px 0 0 ;
  }
  .popup-header{
    width: 92%;
    margin: 0 auto 30px;
    display: flex;
    padding-top: 25px;
    align-items: center;
    justify-items: center;
    .popup-img{
      width: 40px;
      height: 40px;
      display: block;
      margin-right: 10px;
    }
    .right{
      .p1{
        font-size: 16px;
        color: #444444;
        font-weight: 600;
        margin-bottom: 8px;
        margin-top: 4px;
      }
      .p2{
        font-size: 12px;
        color: #aaaaaa;
      }
    }
  }
  .from{
    width: 92%;
    height: 48px;
    background-color: #f9fcff;
    font-size: 14px;
    color: #222222;
    border-radius: 10px;
    margin: 0 auto;
    line-height: 48px;
    padding-left: 20px;
    box-sizing: border-box;
    margin-bottom: 15px;
    border: solid 1px #f1f6f9;
  }
  .vipnut{
    width: 92%;
    height: 48px;
    background-color: #f9fcff;
    font-size: 14px;
    color: #222222;
    border-radius: 10px;
    margin: 0 auto;
    line-height: 48px;
    box-sizing: border-box;
    margin-bottom: 15px;
    display: block;
    padding-left: 20px;
    border: solid 1px #f1f6f9;
  }
  .newvipnut{
    width: 92%;
    height: 48px;
    background-color: #f9fcff;
    font-size: 14px;
    color: #222222;
    border-radius: 10px;
    margin: 0 auto;
    line-height: 48px;
    box-sizing: border-box;
    margin-bottom: 15px;
    display: block;
    border: solid 1px #f1f6f9;
    padding-left: 5px;
    /deep/.van-cell{
      background-color: #f9fcff;
      .van-button--primary{
        font-size: 14px;
        color: #0882e9;
        background-color: #f9fcff;
        border:none;
        border-left: solid 1px #edeff1;
      }
    }
  }
  .button{
    width: 92%;
    height: 48px;
    border-radius: 10px;
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    line-height: 48px;
    margin: 0 auto;
    background-color: #0786f2;
    margin-top: 30px;
  }
  .newbutton{
    width: 92%;
    height: 48px;
    border-radius: 10px;
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    line-height: 48px;
    margin: 0 auto;
    background-color: #aaaaaa;
    margin-top: 30px;
  }
  /deep/.van-skeleton{
    margin-top: 20px;
  }
</style>