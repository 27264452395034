<!--
 * @Author: your name
 * @Date: 2022-04-02 10:19:23
 * @LastEditTime: 2022-04-28 10:49:23
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifi\src\views\index\components\funboxitem.vue
-->
<template>
  <div class="funboxitem" :style="styleOBJ" @click="gopath">
    <div class="isloading" v-if="item.isloading" :style="style">
       <van-loading :color="item.color" :size="size"/>查询中
    </div>
    <slot><img :src="item.url" alt="" class="img" :style="imgstyle"></slot>
    <div class="text" :style="styleText">{{item.text}}</div>
  </div>
</template>
<script>
export default {
  data() {
    return{
      styleOBJ:{
        flexBasis:""
      },
      ratio:20,
      style:{
        color:this.textcolor
      },
      styleText:{
        color:this.bottomtext
      },
      imgstyle:this.imgInfo
    }
  },
  watch:{
    hownum:{
      handler(newv,oldn){
        this.styleOBJ.flexBasis = (100/newv) + "%"
      },
      immediate:true,
      deep:true
    },
    textcolor:{
      handler(newvalue,oldvalue){
        this.style.color = newvalue
      }, 
      deep:true
    }
  },
  props:{
    // 传入的对象
    item:{
      type:Object,
      default:() =>{
       return {
          url:require("@/assets/images/index/setmeal.png"),
          text:"套餐办理"
       }
      }
    },
    // 一行有几个元素
    hownum:{
      type:Number | String,
      default:5
    },
    // loading加载的大小
    size:{
      type:String | Number,
      default:14
    },
    // 字体颜色
    textcolor:{
      type:String,
      default:"#fff"
    },
    // 底下text的字体颜色
    bottomtext:{
      type:String,
      default:"#fff"
    },
    // 图片大小
    imgInfo:{
      type:Object,
      default:()=>{
        return {
          width:28+"px",
          height:28+"px"
        }
      }
    }
  },
  methods:{
    gopath(){
      if(this.item.path){  
        if(this.item.path.indexOf("/recharge") != -1 || this.item.path.indexOf("/selectpack") != -1){
          let data = {
            path:this.item.path,
            iccid:sessionStorage.getItem("iccid"),
            phone:sessionStorage.getItem("phone")
          }
          this.$emit("loginpath",data)
        }else if (this.item.isswitch){
          this.$emit("isswitch")
        }else{
           this.$router.push(this.item.path)
        }
      }else{
        this.$emit("nopath")
        return false
      }
    }
  }
}
</script>
<style lang="less" scoped>
.funboxitem{
  display: flex;
  //flex-basis: 20%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  .isloading{
    display: flex;
    line-height: 24px;
    color: #fff;
    /deep/.van-loading{
      margin-right: 4px;
    }
  }
  .img{
    // width: 28px;
    // height: 28px;
    display: block;
  }
  .text{ 
    font-size: 12px;
    color: #99c0ff;
    margin-top: 15px;
  }
}
</style>
