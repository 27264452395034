<!--
 * @Author: your name
 * @Date: 2022-04-01 10:56:15
 * @LastEditTime: 2022-06-22 13:47:08
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifi\src\views\index\index.vue
-->
<!--
 * @Author: your name
 * @Date: 2022-04-01 10:49:26
 * @LastEditTime: 2022-04-01 10:50:38
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \newwifi\src\views\guide\index.vue
-->
<template>
  <div class="index">
    <div
      class="indexr-center"
      :class="{issmall:windowHeight}"
    >
      <div class="emptybox"></div>
      <div class="center-top">
        <div class="left">
          <img
            src="@/assets/images/index/wifilist.png"
            alt=""
            class="wifi-img"
            @click="clkShowChange"
          >
          <img
            src="@/assets/images/index/division.png"
            alt=""
            class="division"
          >
          <div class="texttitle">{{showWName}}</div>
          <div
            class="left-status"
            :class="{noBuy:info.iccid_status != '正常' && info.iccid_status != '套餐到期',timeout:info.iccid_status == '套餐到期'}"
          >
            {{info.iccid_status}}
            <img
              src="@/assets/images/index/horn.png"
              alt=""
              class="hron"
              v-if="info.buy_id > 0 && info.ispick !=2"
            >
            <img
              src="@/assets/images/index/status.png"
              alt=""
              class="img"
              v-if="info.iccid_status == '正常'"
            >
            <!-- <img src="@/assets/images/index/abnormal.png" alt="" class="img" v-if="info.iccid_status != '正常' && info.buy_id !=0 "> -->
            <img
              src="@/assets/images/index/person.png"
              alt=""
              class="img"
              v-if="info.buy_id > 0 && info.ispick !=2 && info.iccid_status != '套餐到期'"
            >
            <img
              src="@/assets/images/index/timeout.png"
              alt=""
              class="img"
              v-if="info.iccid_status == '套餐到期'"
            >
          </div>
        </div>
        <!-- <div class="right" @click="clkrestart"></div> -->
        <div
          class="right"
          @click="editShow = !editShow"
        ></div>
      </div>
      <div
        class="expire-day"
        v-if="reachTip"
      >
        <div class="text">卡片到期前5天将停机,为不影响您的使用请及时充值</div>
        <div
          class="rechbtn"
          @click="newloginpath()"
        >去续费</div>
      </div>

      <div
        class="noNameTr"
        v-if="isShowToast || info.iccid_status == '套餐到期' "
        @click="goSwitch"
        :class="{timeoutImg:info.iccid_status == '套餐到期'}"
      >
        <div class="toptitle">{{info.iccid_status == '套餐到期'?'套餐已到期':'卡片未实名'}}
        </div>
        <div class="bottomtitle">
          {{info.iccid_status == '套餐到期'?'请先选择套餐购买继续使用':'根据相关规定,请先实名认证'}}</div>
      </div>
      <div class="echarts">
        <div
          id="myChart"
          :style="{ width: '260px', height: '270px' }"
        ></div>
        <div class="logoPic">
          <img
            v-if="info.real_type==2"
            src="~@/assets/images/index/chinaUnicome.png"
            alt=""
          >
          <img
            v-if="info.real_type==3"
            src="~@/assets/images/index/chinamove.png"
            alt=""
          >
          <img
            v-if="info.real_type==1"
            src="~@/assets/images/index/chinadian.png"
            alt=""
          >
        </div>
        <div class="echbox-text">
          <div class="text-top">{{surplus_flow}}<span
              class="text-sapn">GB</span></div>
          <div
            class="surplus"
            v-if="percentage != ''"
          >
            <span class="surplus-sapn"></span> {{percentage}}
          </div>
          <div
            class="newsurplus"
            v-else
          ></div>
          <div
            class="text-center"
            @click="clkRes"
            v-if="isrotate"
          >
            <img
              src="@/assets/images/index/refresh.png"
              alt=""
              class="text-center-img"
            >重启卡片
          </div>
          <div
            class="text-change"
            v-else
          >
            <img
              src="@/assets/images/index/refresh.png"
              alt=""
              class="text-center-img"
            >{{textRotate}}
          </div>
          <div class="text-right">
            <!-- <div class="textrp">已使用流量</div>
             <div class="texttb" v-if="this.value != ''">{{percentage}}</div> -->
          </div>
        </div>
      </div>
      <div class="funbox">
        <funboxitem
          v-for="(item,index) in funbox"
          :key="index"
          :hownum="funbox.length"
          :item="item"
          @loginpath="loginpath($event)"
          @isswitch="newswifiShow = true"
          :bottomtext="'#99c0ff'"
          @nopath="nopathFn()"
        ></funboxitem>
      </div>
      <div class="info">
        <div class="info-top"></div>
        <div class="infobox">
          <funboxitem
            v-for="(item,index) in infolist"
            :key="index"
            :hownum="infolist.length"
            :item="item"
            :bottomtext="'#99c0ff'"
          >
            <div class="text-top">
              <span
                class="sapn"
                :class="{isstatus:index == 3?true:false}"
                :style="{color:item.texttop}"
              >{{item.money}}</span>
              {{item.company}}
            </div>
          </funboxitem>
        </div>
        <!-- <div
          class="info-bottom"
          v-if="isExperience"
        >
          <div class="bottom-left">{{info.buy_name}}</div>
          <div
            class="bottom-right"
            @click="newloginpath()"
          >{{buttontext}}</div>
        </div> -->

        <div
          class="info-bottom"
          @click="newloginpath()"
          v-if="reachTip"
        >
          <div class="bottom-left">卡片到期前5天将停机,为不影响您的使用请及时充值</div>
          <!-- <div
            class="bottom-right"
            @click="newloginpath()"
          >{{buttontext}}</div> -->
        </div>

        <div
          class="info-dataplus"
          v-if="isOrder>0&&info.exp_days>0"
        >
          <div class="info-dataplus-left">
            <img
              v-if="surplus_flow >80"
              src="~@/assets/images/index/floow.png"
              alt=""
            >
            <img
              v-else
              src="~@/assets/images/index/floowY.png"
              alt=""
            >
            <p :style="{color:surplus_flow <80?'#ffed00':'#aabbd4'}">
              剩余流量不足？去购买加油包</p>
          </div>
          <div
            class="goDatapuls"
            :style="{  border:surplus_flow <80?'1px solid rgb(255, 32, 32)':'1px solid #aabbd4',color:surplus_flow <80?'white':'#aabbd4',backgroundColor:surplus_flow <80?'#ff2020':'rgb(255,255,255,0)'}"
            @click="goDatapuls()"
          >去购买</div>
        </div>
      </div>
    </div>
    <div
      class="toastBuyName"
      v-if="info.buy_id == 0"
    >
      <div>该卡片尚未购买套餐,请先购买套餐</div>
      <div
        class="Nright"
        @click="newloginpath()"
      >去购买</div>
    </div>
    <umask
      :show=show
      @closeshow="closeshow($event)"
      @click.stop
    >
      <div
        class="restartcard"
        @click.stop
      >
        <img
          src="@/assets/images/index/100.png"
          alt=""
          class="rcimg"
        >
        <div class="rectitle">刷新卡片</div>
        <div class="rectoast">即将刷新卡片，请确认是否刷新！</div>
        <div
          class="queryclick"
          @click="queryrestart"
        >确认刷新</div>
        <div
          class="closeclick"
          @click="show =false"
        >暂不刷新</div>
      </div>
    </umask>
    <restartloading
      :show="restartloading"
      @closeint="closeint"
    ></restartloading>
    <complete
      :show="complete"
      @clcomplete="clcomplete"
    ></complete>
    <van-popup
      v-model="editShow"
      position="bottom"
      :style="{ height: '180px',background:'#f5f7fa' }"
      round
    >
      <div class="edit">
        <van-cell-group>
          <van-field
            v-model="wifiName"
            placeholder="请输入卡片别名"
            maxlength="9"
          /><img
            src="@/assets/images/index/editb.png"
            alt=""
            class="van-img"
          >
        </van-cell-group>
        <div
          class="editButton"
          @click="setAName"
        >完成设置</div>
      </div>
    </van-popup>
    <newswifi
      :show="newswifiShow"
      @clkOverlay="newswifiShow = !newswifiShow"
      @change="change"
      :cardinfo="info"
      :setName="setName"
      @overGetlist="overGetlist"
    ></newswifi>
    <bindphone
      :phoneShow="phoneShow"
      :iccid="iccid"
      @overlay="phoneShow = false"
      @clkIcon="phoneShow = false"
    ></bindphone>
  </div>
</template>
<script>
import bindphone from "@/components/bindphone/index.vue"
import funboxitem from "@/views/index/components/funboxitem.vue"
import umask from "@/components/umask/index.vue"
import mixin from "@/mixin/mixin.js"
import restartloading from "@/views/index/components/restartloading/"
import complete from "@/views/index/components/complete/"
import newswifi from "@/components/newswifi/index.vue"
import isweixin from "@/untils/isweixin.js"
export default {
  data () {
    return {
      reachTip: false, // 充值提示
      isOrder: 0, //是否买过套餐
      funbox: [
        {
          url: require("@/assets/images/index/balance.png"),
          text: "余额充值",
          path: "/recharge",
          isswitch: false
        },
        {
          url: require("@/assets/images/index/setmeal.png"),
          text: "套餐办理",
          path: "/selectpack",
          isswitch: false
        },
        {
          url: require("@/assets/images/index/addcard.png"),
          text: "新增卡片",
          isswitch: true
        },
        {
          url: require("@/assets/images/index/switch.png"),
          text: "切换卡片",
          path: "/switchwifi",
          isswitch: true
        }
      ], // 功能区域列表
      setwindowHeight: document.documentElement.clientHeight, // 屏幕的实时高度
      windowHeight: false, // 屏幕实时设置
      iccid: "", // iccid号码(短号)
      info: {}, // 主页的所有信息
      show: false, // 重启遮罩层
      restartloading: false, // 重启的loading
      complete: false,  // 重启完成的loading
      currentRate: 0,
      value: 0,  // 使用百分比
      claerset: false,  // 清除定时器
      myChartDom: "", // 已经被赋值的dom图表
      setit: "",  // 定时器的名字
      percentage: "",  // 百分比
      isShowToast: false,  // 是否隐藏当前实名认证
      option: {
        series: [
          {
            type: 'gauge',
            progress: {
              show: true,
              width: 2
            },
            axisLine: {
              lineStyle: {
                width: 2
              }
            },
            itemStyle: {
              color: "#15d85a",
              borderCap: "butt"
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false,
            },
            axisLabel: {
              show: false
            },
            // 关闭指针
            pointer: {
              show: false
            },
            title: {
              show: false,
            },
            // 显示内容
            detail: {
              valueAnimation: true,
              fontSize: 12,
              offsetCenter: [0, '-30%'],
              fontSize: 12,
              fontFamily: "bahn",
              fontWeight: 'bolder',
              formatter: "卡片剩余流量",
              color: '#d4e4ff',
              show: true
            },
            data: [{
              value: "0"
            }]
          }
        ]
      },  //echarts图表数据
      editShow: false,  // 给wifi起别名的弹窗
      wifiName: "",   // 修改的wifi别名
      showWName: "",  // 要显示出来的WIFI别名
      newswifiShow: false,  // 新的wifi弹窗是否显示(用于切换wifi设备)
      setName: false,   // 设置过wifi别名之后
      isrotate: false,  // 是否展示正在刷新
      textRotate: "查询中...",  //是否展示正在查询流量
      surplus_flow: "--",  // 剩余的总量（GB）
      noclickBtn: false,  // 当切换wifi设备的时候禁止点击未购买套餐和未实名框，true是禁止
      phoneShow: false,  // 如果手机号被硕郎方清除，则拉起手机号绑定弹窗
      weixin: false
    }
  },
  mixins: [mixin],
  mounted () {
    this.weixin = isweixin()


    this.myChartDom = this.$echarts.init(document.getElementById("myChart"))
    this.setIccid()


    this.getinfo()


    this.getoffinfo(1)
    // .then(res => {
    //   if (this.infolist[1].usedMoney) {
    //     this.infolist[1].money = Math.round(Math.ceil(this.info.total_flow - this.infolist[1].usedMoney) / 1024)>= 0?Math.round(Math.ceil(this.info.total_flow - this.infolist[1].usedMoney) / 1024):0
    //     this.infolist[1].company = "GB"
    //     // console.log(this.offcardinfo)
    //     // this.surplus_flow = Math.trunc(this.offcardinfo.surplus_flow/1024)
    //   }

    // })
    this.addValue()



  },
  beforeRouteLeave (to, from, next) {
    // 导航离开该组件的对应路由时调用
    clearInterval(this.setit)
    // sessionStorage.setItem("surplus","--")
    next()    //允许跳转页面
  },
  computed: {
    // 如果是1000-2天则不显示
    isExperience () {
      if (this.info.exp_days <= 0) {
        return false
      } else {
        return true
      }
    },
    // 按钮显示的文字
    buttontext () {
      if (this.info.pay_status == 1) {
        return "去续期"
      } else {
        return "去订购"
      }
    },
    text () {
      return this.currentRate.toFixed(0) + '%';
    },
  },
  watch: {
    setwindowHeight: {
      // 获取当前屏幕的高度,用于兼容小屏幕手机的手机
      handler (newv, oldv) {
        if (newv <= 667) {
          this.windowHeight = true
        } else {
          this.windowHeight = false
        }
      },
      immediate: true,
      deep: true
    },
    // 如果计算的值是一个无穷大
    percentage: {
      handler (newv, oldv) {
        console.log(newv);
        let bol = (newv + "").indexOf("fin")
        if (bol != -1) {
          this.percentage = ""
          // this.clkRes()
        }
      }
    }
  },
  methods: {
    //没有传地址的时候  新增卡片
    nopathFn () {
      const openid = sessionStorage.getItem('openid')
      // alert(openid);
      window.location.href = "https://iot.yuna6.com/card.html#" + openid
    },


    // 点击切换wifi
    clkShowChange () {
      this.newswifiShow = true
    },
    // 根据返回设备状态判断来进行跳转
    goSwitch () {
      if (this.noclickBtn) {
        return false
      }
      if (this.info.iccid_status == "套餐到期") {
        this.newloginpath()
      } else {
        this.goPick()
      }
    },
    // 更改过wifi名字之后
    overGetlist () {
      this.setName = false
    },
    // 切换wifi设备
    async change () {
      this.phoneShow = false
      this.noclickBtn = true
      await this.getinfo()
      // sessionStorage.setItem('')
      // sessionStorage.getItem('isOrder')
      sessionStorage.removeItem('isOrder')
      this.newswifiShow = !this.newswifiShow
      this.noclickBtn = false
      this.clkRes()
    },
    // 点击完成设置修改wifi别名
    setAName () {
      let data = {
        iccid: sessionStorage.getItem("iccid"),
        name: this.wifiName
      }
      this.setAliasName({ data }).then(res => {
        if (this.wifiName == "") {
          this.$toast("WIFI名称不能为空")
          return false
        }
        if (res.data.code == 1) {
          this.$toast.success("设置成功")
          this.editShow = false
          sessionStorage.setItem("wifiName", this.wifiName)
          this.showWName = sessionStorage.getItem('wifiName')
          this.setName = true
          this.wifiName = ""
        } else {
          this.$toast(`${res.data.msg}`)
        }
      })
    },
    // 点击订购套餐
    newloginpath () {
      let data = {
        path: "/selectpack",
        iccid: sessionStorage.getItem("iccid"),
        phone: sessionStorage.getItem("phone")
      }
      this.isopenid(data)
    },

    goDatapuls () {
      let data = {
        path: "/dataPlus",
        iccid: sessionStorage.getItem("iccid"),
        phone: sessionStorage.getItem("phone")
      }
      this.isopenid(data)

    },



    // 去实名认证
    goPick () {
      window.sessionStorage.setItem("openShow", 1)
      window.location.href = this.info.real_url
    },
    // 进行仪表盘累加,当做加载动画
    addValue () {
      // sessionStorage.setItem("surplus","--")
      this.canverClire()
      this.setit = setInterval(() => {
        if (this.claerset) {
          clearInterval(this.setit)
          return false
        }
        if (this.value > 90) {
          this.value = 20
        } else {
          this.value = this.value + 2
        }
        this.canverClire(this.value)
      }, 1000)
    },
    //刷新数据按钮
    clkRes () {
      this.surplus_flow = "--"
      this.infolist[1].usedMoney = ''
      this.claerset = false
      this.value = 0
      this.isrotate = false
      this.textRotate = "刷新中..."
      this.infolist[1].money = ""
      this.infolist[1].company = ""
      this.infolist[1].isloading = true
      this.addValue()
      this.getoffinfo(1)
      // .then(res => {
      //   // console.log(this.infolist[1].usedMoney, 'sss');
      //   if (this.infolist[1].usedMoney) {
      //     this.infolist[1].money = Math.round(Math.ceil(this.info.total_flow - this.infolist[1].usedMoney) / 1024)>= 0?Math.round(Math.ceil(this.info.total_flow - this.infolist[1].usedMoney) / 1024):0
      //     this.infolist[1].company = "GB"
      //         //  this.surplus_flow = Math.trunc(this.offcardinfo.surplus_flow/1024)


      //   }
      // })
      //  console.log( this.infolist,' this.infolist');
    },
    // echarts图表数据进行画图
    canverClire (value) {
      this.option.series[0].data[0].value = value
      // this.option.series[0].detail.formatter = sessionStorage.getItem("surplus")
      this.myChartDom.setOption(this.option, true)
    },
    // 路由跳转
    gopath (path) {
      this.$router.push(path)
    },
    // 获取首页的所有信息
    getinfo () {
      this.surplus_flow = "--"
      let data = {
        iccid: sessionStorage.getItem("iccid") ? sessionStorage.getItem("iccid") : this.iccid
      }

      this.getindexinfo({ data }).then(res => {
        // console.log(res,'iccidinfo');
        if (res.data.code == 1) {
          this.isOrder = res.data.info.buy_id

          if (sessionStorage.getItem('isOrder')) {
            // console.log("0是没有买过套餐");
            sessionStorage.setItem('isOrder', 1)

          } else {
            if (this.weixin) {


              if (this.$route.query.dn) {
                sessionStorage.setItem('openid', this.$route.query.openid)
              } else {
                var openid = window.location.href.split("#")[1];
                sessionStorage.setItem('openid', openid)
              }

              //  不存在 设置isorder
              sessionStorage.setItem('isOrder', this.isOrder)
              if (sessionStorage.getItem('isOrder') == 0) {
                //去订购套餐
                this.newloginpath()
              }


            }
            //  不存在 设置isorder
            sessionStorage.setItem('isOrder', this.isOrder)
            if (sessionStorage.getItem('isOrder') == 0) {
              //去订购套餐
              this.newloginpath()
            }



          }
          this.info = res.data.info



          if (this.info.sl_real_state == 0) {
            // 有时接口过慢，如果切换设备的弹窗开启 主动隐藏掉
            this.phoneShow = true
            this.newswifiShow = false
          } else {
            this.phoneShow = false
          }
          window.sessionStorage.setItem("wifiName", this.info.alias_name)
          this.showWName = this.info.alias_name

          if (
            this.info.pay_status > 0 && this.info.exp_days <= 10 && this.info.c_balance < 29 && this.info.ispick == 2
          ) {
            this.infolist[2].texttop = "#fff600"
            this.reachTip = true
          }

          if (this.info.iccid_status != "正常") {
            this.infolist[3].texttop = "#fff600"
          }
          if (this.info.buy_id > 0) {
            if (this.info.ispick != 2) {
              this.isShowToast = true
            } else {
              this.isShowToast = false
            }
          } else {
            this.isShowToast = false
          }
          this.infolist[0].money = this.info.c_balance
          this.infolist[2].money = this.info.exp_days
          this.infolist[3].money = this.info.iccid_status
          sessionStorage.setItem("phone", res.data.info.phone)

        } else {
          this.$toast(`${res.data.msg}`)
        }
        // console.log(this.info);
      })

    },
    // 获取iccid
    setIccid () {
      if (sessionStorage.getItem("iccid") != undefined && sessionStorage.getItem("iccid") != null && sessionStorage.getItem("iccid") != "null" && sessionStorage.getItem("iccid") != "undefined") {
        this.iccid = sessionStorage.getItem("iccid")
      } else {
        this.iccid = this.$route.query.dn
        sessionStorage.setItem("iccid", this.iccid)
      }
    },
    // 父组件监听到了子组件的值改变之后去关闭遮罩层
    closeshow ($event) {
      this.show = $event
    },
    // 点击设备重启
    restart () {
      if (JSON.stringify(this.info) == "{}") {
        return false
      }
      this.show = true
    },
    // 确定重启当前卡片
    async queryrestart () {
      this.restartloading = true
      this.show = false
      await this.getoffinfo(1)
      // .then(res => {
      //   if (this.infolist[1].usedMoney) {
      //     this.infolist[1].money = Math.round(Math.ceil(this.info.total_flow - this.infolist[1].usedMoney) / 1024) >= 0?Math.round(Math.ceil(this.info.total_flow - this.infolist[1].usedMoney) / 1024):0
      //     this.infolist[1].company = "GB"
      //           // this.surplus_flow = Math.trunc(this.offcardinfo.surplus_flow/1024)
      //   }
      //           // this.surplus_flow = Math.trunc(this.offcardinfo.surplus_flow/1024)
      // })
      await this.getinfo()
    },
    //10秒倒计时执行完毕
    closeint () {
      this.restartloading = false
      this.complete = true
    },
    // 20秒倒计时结束
    clcomplete () {
      this.complete = false
    },
    // 点击重启按钮
    clkrestart () {
      this.show = true
    },
  },
  components: {
    funboxitem,
    umask,
    restartloading,
    complete,
    newswifi,
    bindphone
  }
}
</script>
<style lang="less" scoped>
.index {
  min-height: 100vh;
  background-image: url("~@/assets/images/index/bgimg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .indexr-center {
    width: 345px;
    margin: 0 auto;
    .emptybox {
      width: 100%;
      height: 25px;
    }

    .expire-day {
      position: absolute;
      top: 50px;
      width: 100%;
      height: 40px;
      // z-index: 9;
      // margin: 0 auto;
      display: flex;
      transform: translateX(-15px);
      background-color: rgba(8, 0, 0, 0.137);
      box-sizing: border-box;
      align-items: center;
      justify-content: space-between;
      padding: 10px 12px;
      .text {
        font-size: 12px;
        color: #fff600;
      }
      .rechbtn {
        width: 54px;
        height: 24px;
        background-color: #fff600;
        color: #0c3264;
        line-height: 24px;
        text-align: center;
        border-radius: 12px;
      }
    }
    .center-top {
      box-sizing: border-box;
      width: 100%;
      display: flex;
      padding: 0 5px;
      justify-content: space-between;
      justify-items: center;
      align-items: center;
      .left {
        font-size: 18px;
        color: #ffffff;
        font-weight: 600;
        display: flex;
        justify-items: center;
        align-items: center;
        .texttitle {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 180px;
        }
        .wifi-img {
          width: 15px;
          display: block;
        }
        .division {
          width: 4px;
          display: block;
          margin: 0 8px 0 6px;
        }
        .left-status {
          width: 100px;
          margin-left: 12px;
          color: #c6dcff;
          transform: scale(0.9);
          font-size: 12px;
          border-radius: 10px;
          box-sizing: border-box;
          padding: 5px 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          justify-items: center;
          background-color: #2b7adc;
          position: relative;
          .img {
            height: 12px;
            display: block;
            margin-left: 4px;
          }
          .hron {
            position: absolute;
            width: 14px;
            height: 10px;
            display: block;
            top: 24px;
          }
        }
        .timeout {
          color: #ffca71 !important;
        }
        .noBuy {
          color: #fff600 !important;
          padding: 4px 10px;
        }
        .noRName {
          color: #ef0d0d !important;
          background-color: #fff600;
        }
      }
      .right {
        width: 14px;
        display: block;
        height: 14px;
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-image: url("~@/assets/images/index/edit.png");
      }
    }
    .echarts {
      width: 300px;
      height: 300px;
      background-image: url("~@/assets/images/index/eachartsbg.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;

      .logoPic {
        position: absolute;
        top: 70px;
        img {
          width: 70px;
          height: 36px;
        }
      }
      .echbox-text {
        position: absolute;
        min-height: 140px;
        top: 150px;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: flex;
        justify-items: center;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        .text-top {
          color: #fff;
          font-size: 36px;
          font-family: "bahn";
          .text-sapn {
            font-size: 18px;
          }
        }
        .newsurplus {
          width: 76px;
          height: 18px;
        }
        .surplus {
          width: 76px;
          height: 18px;
          border-radius: 9px;
          color: #fff000;
          background-color: #1c6fe7;
          font-size: 12px;
          margin-top: 20px;
          text-align: center;
          line-height: 18px;
          .surplus-sapn {
            font-size: 12px;
            transform: scale(0.8);
          }
        }
        .text-center {
          background-color: #02cdc1;
          display: flex;
          justify-content: center;
          align-items: center;
          justify-items: center;
          min-width: 100px;
          min-height: 28px;
          border-radius: 14px;
          color: #fff;
          font-size: 12px;
          .text-center-img {
            width: 13px;
            display: block;
            margin-right: 4px;
          }
        }
        .text-change {
          background-color: #02cdc1;
          display: flex;
          justify-content: center;
          align-items: center;
          justify-items: center;
          min-width: 84px;
          min-height: 28px;
          border-radius: 14px;
          color: #fff;
          font-size: 12px;
          @-webkit-keyframes change {
            0% {
              -webkit-transform: rotate(0deg);
            }
            50% {
              -webkit-transform: rotate(180deg);
            }
            100% {
              -webkit-transform: rotate(360deg);
            }
          }
          .text-center-img {
            width: 13px;
            display: block;
            margin-right: 4px;
            animation: change 1s linear infinite;
          }
        }
        .text-center:active {
          background-color: #02b7ac;
        }
        .text-right {
          display: flex;
          flex-direction: column;
          justify-items: center;
          align-items: center;
          .textrp {
            color: #99c0ff;
            margin-bottom: 6px;
          }
          .texttb {
            color: #fff;
            font-size: 32px;
          }
        }
      }
    }
    .timeoutImg {
      background-image: url("~@/assets/images/index/time.gif") !important;
    }
    .noNameTr {
      width: 290px;
      height: 96px;
      background-image: url("~@/assets/images/index/noname.gif");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 26px 0 0 80px;
      z-index: 3;
      .toptitle {
        color: #fff600;
        font-size: 16px;
        font-family: "bahn";
        font-weight: 600;
        margin-bottom: 8px;
      }
      .bottomtitle {
        color: #fff5ae;
        font-size: 12px;
        font-family: "bahn";
      }
    }
    .funbox {
      width: 100%;
      height: 108px;
      border-top: #338bf5 solid 1px;
      display: flex;
      flex-wrap: wrap;
    }
    .info {
      min-height: 100px;
      width: 100%;
      background-color: #2173e3;
      margin-bottom: 25px;
      // border-radius: 6px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;

      .info-top {
        height: 30px;
      }
      .infobox {
        height: 48px;
        display: flex;
        flex-wrap: wrap;
        .text-top {
          font-size: 12px;
          color: #ffffff;
          .isstatus {
            font-size: 14px !important;
            line-height: 24px;
            text-align: center;
          }
          .sapn {
            font-size: 24px;
            color: #ffffff;
            font-weight: 500;
            font-family: "bahn";
          }
        }
      }
      .info-dataplus {
        display: flex;
        justify-content: space-between;
        padding: 0 12.5px;
        align-items: center;
        margin-top: 10px;
        height: 40px;
        width: 100%;
        color: white;
        background-color: #12468b2e;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        box-sizing: border-box;

        .info-dataplus-left {
          display: flex;
          align-items: center;
          img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
        }
        .goDatapuls {
          border-radius: 4px;
          padding: 4px 8px;
          border: 1px solid white;
        }
      }
      .info-bottom {
        width: 320px;
        height: 40px;
        margin: 0 auto;
        background-image: url("~@/assets/images/index/reachtip.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        .bottom-left {
          color: #ffffff;
          font-weight: 400;
          font-size: 12px;
          margin: 19px 0 0 24px;
          color: #ffdb33;
          .sapn {
            font-size: 14px;
            color: red;
          }
        }
        .bottom-right {
          width: 56px;
          height: 20px;
          border-radius: 4px;
          background-color: #4c9aff;
          color: #ffffff;
          font-weight: 600;
          margin: 20px 10px 0 0;
          display: flex;
          justify-content: center;
          align-items: center;
          justify-items: center;
        }
      }
    }
  }
  .toastBuyName {
    width: 100%;
    height: 40px;
    background-color: rgba(17, 101, 213, 0.7);
    position: absolute;
    top: 8vh;
    font-size: 12px;
    color: #fff600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    box-sizing: border-box;
    padding: 0 15px;
    .Nright {
      width: 54px;
      height: 24px;
      background-color: #fff600;
      border-radius: 12px;
      color: #0c3264;
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      line-height: 24px;
    }
  }
  .issmall {
    box-sizing: border-box;
    padding-bottom: 60px;
  }
  /deep/.van-overlay {
    z-index: 6 !important;
  }
  .restartcard {
    width: 80%;
    border-radius: 5%;
    height: 275px;
    position: fixed;
    left: 50%;
    top: 50%;
    margin: auto;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .rcimg {
      width: 80px;
      height: 80px;
      display: block;
    }
    .rectitle {
      height: 31px;
      font-weight: 900;
      font-size: 17px;
    }
    .rectoast {
      margin-bottom: 20px;
      color: rgb(68, 68, 68);
    }
    .queryclick {
      width: 85%;
      height: 48px;
      line-height: 43px;
      font-size: 17px;
      background-color: rgb(0, 146, 238);
      font-weight: 900;
      color: rgb(255, 255, 255);
      border-radius: 13px;
      border: 2px solid;
      text-align: center;
    }
    .closeclick {
      padding-top: 13px;
      color: rgb(195, 195, 195);
      font-size: 13px;
    }
  }
  .edit {
    /deep/.van-cell-group {
      width: 92%;
      margin: 16px auto;
      border-radius: 6px;
      overflow: hidden;
      display: flex;
      justify-items: center;
      align-items: center;
      height: 48px;
      .van-field {
        width: 92%;
      }
      .van-cell {
        font-size: 16px;
        font-weight: 500;
        color: #44474d;
      }
      .van-img {
        width: 14px;
        height: 14px;
        display: block;
      }
    }
    /deep/.van-cell-group::after {
      border: none;
    }
    .editButton {
      background: linear-gradient(to right, #3394f4, #2a7ff0, #236ced);
      width: 240px;
      height: 48px;
      color: #fff;
      text-align: center;
      line-height: 48px;
      border-radius: 8px 14px 4px 4px;
      margin: 0 auto;
      font-size: 16px;
      font-weight: 600;
      margin-top: 30px;
    }
    .editButton:active {
      background: #0771ff;
    }
  }
}
</style>